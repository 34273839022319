<template>
  <div :class="`shadow p-5 my-5 bg-white rounded ${isMobile ? 'w-100' : 'w-50'}`">
    <p class="font-bold font-20">{{ $t('change_email') }}</p>
    <div v-if="userInfo">
      <div class="mt-5 d-flex justify-content-between">
        <span class="font-12 alpha-07">Original Email</span>
        <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ getUserEmail(userInfo.email) }}</span>
      </div>
      <div class="mt-5 d-flex justify-content-between">
        <span class="font-12 alpha-07">New Email</span>
        <input :class="isMobile ? '' : 'col-sm-8'" v-model="email" />
      </div>
      <div class="mt-5 d-flex justify-content-end">
        <v-btn class="button-normal" @click="changeGuestEmailTask()">
          {{ $t('modify') }}
        </v-btn>
      </div>
    </div>
    <div class="py-20 center" v-else>
      <p>Please wait ...</p>
      <b-spinner type="grow"></b-spinner>
    </div>
  </div>
</template>


<script>
import { httpsCallable } from 'firebase/functions';
import { functions, firestore } from '../../../main';
import { getDoc, doc } from 'firebase/firestore';
import { getUserEmail, isValidEmail, showLoading } from '../../../functions';

export default {
  name: 'ChangeGuestEmail',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      email: '',
      userInfo: null
    }
  },
  mounted() {
    const userInfo = this.$store.state.userList.find(element => element.userId === this.$route.params.userId);
    if (userInfo) {
      this.userInfo = userInfo;
    } else {
      this.getUserInfoTask();
    }
  },
  methods: {
    getUserEmail,
    getUserInfoTask() {
      const q = doc(firestore, 'user', this.$route.params.userId);
      getDoc(q).then(doc => {
        this.userInfo = doc.data();
      });
    },
    changeGuestEmailTask() {
      if (!this.email) {
        this.$toast.error('Please enter emai')
        return;
      }
      if (!isValidEmail(this.email)) {
        this.$toast.error(this.$t('alert_invalid_email'));
        return;
      }
      if (!confirm('Are you sure you want to change the email address?')) {
        return;
      }
      const params = {
        functionName: 'changeGuestEmail',
        userId: this.$route.params.userId,
        email: this.email
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          const userList = this.$store.state.userList;
          for (var i = userList.length; i--;) {
            if (userList[i].userId === this.$route.params.userId) {
              userList[i].email = this.email;
            }
          }
          this.$store.commit('set', ['userList', userList]);
          this.$toast.success(this.$t('success'));
          this.$router.push(`/users/2`);
        } else if (response.data === this.RESULT_DUPLICATED_EMAIL) {
          this.$toast.error(this.$t('alert_email_already_exist'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>